// Defines the locations displayed on the map (Facility and Release-site)
export class Location {
    constructor(type, name, year, operator, x, y) {
        this.name = name;
        this.year = year;
        this.operator = operator;
        this.x = x;
        this.y = y;
        this.type = type;
    }

    getCoordinates() {
        return [this.x, this.y];
    }

    getName() {
        return this.name;
    }

    getYear() {
        return this.year;
    }

    getOperator() {
        return this.operator;
    }

    getType() {
        return this.type;
    }
}
  